export const canadianLocations = [
    'Alberta',
    'Airdrie, AB',
    'Beaumont, AB',
    'Bonnyville, AB',
    'Brazeau, AB',
    'Breton, AB',
    'Calgary, AB',
    'Canmore, AB',
    'Drayton Valley, AB',
    'Edmonton, AB',
    'Hanna, AB',
    'Hinton, AB',
    'Irricana, AB',
    'Lacombe, AB',
    'Leduc, AB',
    'McLennan, AB',
    'Medicine Hat, AB',
    'Olds, AB',
    'Onoway, AB',
    'Provost, AB',
    'Red Deer, AB',
    'Spruce Grove, AB',
    'St. Albert, AB',
    'Strathcona County, AB',
    'Strathmore, AB',
    'Sylvan Lake, AB',
    'Taber, AB',
    'Turner Valley, AB',
    'Vermillion, AB',
    'Wood Buffalo, AB',
    'Fort Saskatchewan, AB',
    'British Columbia',
    'Burnaby, BC',
    'Cache Creek, BC',
    'Castlegar, BC',
    'Chemainus, BC',
    'Chilliwack, BC',
    'Clearwater, BC',
    'Cranbrook, BC',
    'Dawson Creek, BC',
    'Delta, BC',
    'Fernie, BC',
    'Invermere, BC',
    'Kamloops, BC',
    'Kelowna, BC',
    'Langley, BC',
    'Maple Ridge, BC',
    'Merritt, BC',
    'Nanaimo, BC',
    'Nelson, BC',
    'New Westminster, BC',
    'North Cowichan, BC',
    'North Vancouver, BC',
    'Osoyoos, BC',
    'Parksville, BC',
    'Peace River, BC',
    'Penticton, BC',
    'Port Alberni, BC',
    'Port Hardy, BC',
    'Port Moody, BC',
    'Prince George, BC',
    'Prince Rupert, BC',
    'Richmond, BC',
    'Saanich, BC',
    'Sooke, BC',
    'Sparwood, BC',
    'Surrey, BC',
    'Terrace, BC',
    'Tumbler, BC',
    'Vancouver, BC',
    'Vernon, BC',
    'Victoria, BC',
    'Whistler, BC',
    'Manitoba',
    'Brandon, MB',
    'Dauphin, MB',
    'Flin Flon, MB',
    'Portage la Prairie, MB',
    'Selkirk, MB',
    'Steinbach, MB',
    'Thompson, MB',
    'Winkler, MB',
    'Winnipeg, MB',
    'New Brunswick',
    'Bathurst, NB',
    'Campbellton, NB',
    'Dieppe, NB',
    'Edmundston, NB',
    'Fredericton, NB',
    'Miramichi, NB',
    'Moncton, NB',
    'Saint John, NB',
    'Newfoundland And Labrador',
    'Corner Brook, NL',
    'Mount Pearl, NL',
    'St. John\'s, NL',
    'Northwest Territories',
    'Yellowknife, NT',
    'Nova Scotia',
    'Amherst, NS',
    'Antigonish, NS',
    'Bridgewater, NS',
    'Cape Breton, NS',
    'Colchester, NS',
    'Cumberland, NS',
    'Digby, NS',
    'Glace Bay, NS',
    'Halifax, NS',
    'Kentville, NS',
    'Lunenburg, NS',
    'New Glasgow, NS',
    'Pictou, NS',
    'Port Hawkesbury, NS',
    'Queens, NS',
    'Shelburne, NS',
    'Sydney, NS',
    'Truro, NS',
    'Yarmouth, NS',
    'Ontario',
    'Ajax, ON',
    'Barrie, ON',
    'Belleville, ON',
    'Brampton, ON',
    'Brantford, ON',
    'Brockville, ON',
    'Burlington, ON',
    'Cambridge, ON',
    'Chatham-Kent, ON',
    'Clarington, ON',
    'Cornwall, ON',
    'East Gwillimbury, ON',
    'Guelph, ON',
    'Hamilton, ON',
    'Kenora, ON',
    'Kingston, ON',
    'Kitchener, ON',
    'London, ON',
    'Markham, ON',
    'Mississauga, ON',
    'Niagara Falls, ON',
    'Norfolk, ON',
    'North Bay, ON',
    'Oakville, ON',
    'Orillia, ON',
    'Oshawa, ON',
    'Ottawa, ON',
    'Owen Sound, ON',
    'Pembroke, ON',
    'Peterborough, ON',
    'Pickering, ON',
    'Port Colborne, ON',
    'Prince Edward, ON',
    'Quinte West, ON',
    'Richmond Hill, ON',
    'Sarnia, ON',
    'Sault Ste. Marie, ON',
    'St. Catharines, ON',
    'St. Thomas, ON',
    'Stratford, ON',
    'Temiskaming Shores, ON',
    'Thorold, ON',
    'Thunder Bay, ON',
    'Timmins, ON',
    'Toronto, ON',
    'Vaughan, ON',
    'Waterloo, ON',
    'Welland, ON',
    'Windsor, ON',
    'Woodstock, ON',
    'Prince Edward Island',
    'Alberton, PE',
    'Charlottetown, PE',
    'Cornwall, PE',
    'Montague, PE',
    'Souris, PE',
    'Summerside, PE',
    'Quebec',
    'Beauport, QC',
    'Chicoutimi, QC',
    'Drummondville, QC',
    'Gatineau, QC',
    'Jonquiere, QC',
    'LaSalle, QC',
    'Laval, QC',
    'Longueuil, QC',
    'Montreal, QC',
    'Quebec, QC',
    'Repentigny, QC',
    'Rimouski, QC',
    'Saguenay, QC',
    'Saint-Hyacinthe, QC',
    'Saint-Jean-sur-Richelieu, QC',
    'Saint-Jerome, QC',
    'Saint-Leonard, QC',
    'Sherbrooke, QC',
    'Terrebonne, QC',
    'Trois-Rivieres, QC',
    'Saskatchewan',
    'Estevan, SK',
    'Humboldt, SK',
    'Moose Jaw, SK',
    'North Battleford, SK',
    'Prince Albert, SK',
    'Regina, SK',
    'Saskatoon, SK',
    'Swift Current, SK',
    'Warman, SK',
    'White City, SK',
    'Whitehorse, YT',
    'Yukon'
];
