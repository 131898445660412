import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Typography from '../component/Typography';
import AppAppBar from '../component/AppAppBar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const PricingPage = () => {
  const [pricingPlans, setPricingPlans] = useState([]);

  useEffect(() => {
    const fetchPricingDetails = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/pricing-plans`);
        // Sort pricing plans by price in ascending order
        const sortedPlans = response.data.sort((a, b) => a.price - b.price);
        setPricingPlans(sortedPlans);
      } catch (error) {
        console.error('Error fetching pricing details:', error);
      }
    };

    fetchPricingDetails();
  }, []);

  const handleBuyNow = async (planName) => {
    try {
      const token = localStorage.getItem('token');

      // Check if the token exists
      if (!token) {
        toast.error('Please log in to purchase a plan.');
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/create-checkout-session`,
        { selectedPlan: planName },
        { headers: { 'Authorization': `${token}` } }
      );

      const checkoutUrl = response.data.checkoutUrl;

      // Redirect to the checkout page
      window.location.href = checkoutUrl;
    } catch (error) {
      const errorData = error.response.data;
      console.log(errorData.message);
      toast.error(errorData.message);
    }
  };

  return (
    <React.Fragment>
      <AppAppBar />
      <Box
        sx={{
          display: 'flex',
          backgroundImage: 'url("http://tinyurl.com/3yfbbbun")',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          width: '100%',
          backgroundColor: '#f5f5f5',
        }}
      >
        <Container maxWidth="lg">
          <Typography variant="h4" gutterBottom marked="center" align="center" sx={{ mt: 3, mb: 2 }}>
            Pricing Plans
          </Typography>
          <Grid container spacing={3} justifyContent="center">
            {pricingPlans.map((plan) => (
              <Grid item key={plan._id} xs={12} sm={6} md={4} lg={3}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="h5" mb={2} align="center">
                      {plan.name}
                    </Typography>
                    <Typography variant="h4" mb={2} align="center">
                      ${plan.price}
                    </Typography>
                    <Typography variant="body2" mb={2} align="center">
                      {plan.description}
                    </Typography>
                    <ul>
                      {plan.features.map((feature, index) => (
                        <li key={index} style={plan.name === 'Platinum' && feature.includes('Unlimited Access to Candidate Search') ? { fontWeight: 'bold', color: 'blue' } : {}}>
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 'auto', pb: 2 }}>
                    {plan.price !== 0 ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleBuyNow(plan.name)}
                      >
                        Buy Now
                      </Button>
                    ) : (
                      <Typography variant="caption" color="textSecondary">
                        Free Plan
                      </Typography>
                    )}
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
      <ToastContainer />
    </React.Fragment>
  );
};

export default PricingPage;
